var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.sso.authenticated() && _vm.appConfig.dashboard.myLibrary.searchable
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                staticClass: "mt-0",
                attrs: {
                  autofocus: _vm.$vuetify.breakpoint.lgAndUp,
                  "background-color": _vm.raiseSearchBar ? "" : "background",
                  "aria-label": _vm.ae$lng("pages.home.searchForEDocuments"),
                  flat: "",
                  solo: "",
                  outlined: _vm.raiseSearchBar,
                  "hide-details": "",
                  placeholder: _vm.ae$lng("pages.home.searchExample"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prepend-inner",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { color: "red" } }, [
                            _vm._v("mdi-magnify"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1033578605
                ),
                model: {
                  value: _vm.drawerFilter,
                  callback: function ($$v) {
                    _vm.drawerFilter = $$v
                  },
                  expression: "drawerFilter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.homeLibrary, function (item, i) {
        return _c(
          "v-col",
          {
            key: i,
            attrs: {
              cols: "12",
              md: _vm.appConfig.dashboard.myLibrary.cols[
                _vm.isPage ? "cardsInPage" : "cardsInWidget"
              ].md,
              lg: _vm.appConfig.dashboard.myLibrary.cols[
                _vm.isPage ? "cardsInPage" : "cardsInWidget"
              ].lg,
              xl: _vm.appConfig.dashboard.myLibrary.cols[
                _vm.isPage ? "cardsInPage" : "cardsInWidget"
              ].xl,
            },
          },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-card",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "pa-4 fill-height",
                                  class: {
                                    "home-card": _vm.sso.authenticated(),
                                    "home-login": !_vm.sso.authenticated(),
                                  },
                                  attrs: {
                                    hover: "",
                                    elevation: _vm.sso.authenticated() ? 0 : 8,
                                    flat: "",
                                    outlined: "",
                                  },
                                },
                                "v-card",
                                item.target,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", {
                                        staticClass:
                                          "font-weight-light caption text-truncate",
                                        staticStyle: { "margin-left": "1px" },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.ae$prefixLng(
                                              _vm.mI18n(item) + "._library",
                                              item["parentName"]
                                            )
                                          ),
                                        },
                                      }),
                                      _c("v-list-item-title", {
                                        staticClass:
                                          "font-weight-medium text-truncate",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.ae$prefixLng(
                                              _vm.mI18n(item) + "._library",
                                              item["name"]
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      attrs: {
                                        horizontal: "",
                                        right: "",
                                        size: _vm.appConfig.dashboard.myLibrary
                                          .avatarSize[0],
                                        color: _vm.getMeta(item).color,
                                      },
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          dark: !_vm.getMeta(item).light,
                                          light: _vm.getMeta(item).light,
                                          size: _vm.appConfig.dashboard
                                            .myLibrary.avatarSize[1],
                                        },
                                        domProps: {
                                          textContent: _vm._s(item["icon"]),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-truncate pt-0 mt-0" },
                                [
                                  _c("span", { staticClass: "text-truncate" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ae$prefixLng(
                                          _vm.mI18n(item) +
                                            "._library._description",
                                          item["description"]
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ae$prefixLng(
                        _vm.mI18n(item) + "._library._description",
                        item["description"]
                      )
                    )
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }