var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("asyent-container", [
    _c("div", { staticClass: "dashboard" }, [
      _vm.appConfig.dashboard.myLibrary.enable
        ? _c(
            "div",
            { staticClass: "dashboard-item" },
            [
              _c(
                "asyent-dashboard-panel",
                {
                  attrs: {
                    icon: "mdi-magnify",
                    "avatar-color": "red",
                    title: _vm.ae$lng("pages.home.searchForEDocuments"),
                    hidden: _vm.appConfig.dashboard.myLibrary.collapse,
                  },
                },
                [_c("Library")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appConfig.dashboard.userAccounts.enable &&
      _vm.sso.hasRoles("connectid-account-review")
        ? _c("div", { staticClass: "dashboard-item" }, [_c("AccountTasks")], 1)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }