var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "title-icon": "mdi-menu",
      },
    },
    [
      _c(
        "asyent-dashboard-panel",
        {
          attrs: { "ignore-mobile": "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("span", [_vm._v(" ")])]
              },
              proxy: true,
            },
          ]),
        },
        [_c("Library", { attrs: { "is-page": "" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }